<template>
  <div>
    <slot name="app-before" />
    <div id="app-before" />
    <PageNavbar />
    <UContainer>
      <div class="grid grid-cols-12 gap-3 my-8 min-h-[calc(100vh-404px)]">
        <div class="col-span-12 lg:col-span-3">
          <div
            class="w-full flex flex-col h-full border-r-[1px] border-neutral-100 dark:border-neutral-800"
          >
            <div class="flex items-center">
              <UChip size="3xl" inset color="info">
                <UAvatar
                  size="4xl"
                  :src="userStore.imageUrl"
                  :alt="`${userStore.firstName} ${userStore.lastName}`"
                  loading="lazy"
                />
              </UChip>
              <div class="flex flex-col items-start ml-3">
                <p class="text-lg font-bold line-clamp-1">
                  {{ userStore.firstName }} {{ userStore.lastName }}
                </p>
                <hr class="my-1 border-neutral-100 w-full dark:border-neutral-800" />
                <p class="text-amber-500 font-bold text-sm">
                  {{ userStore.point }} points
                </p>
              </div>
            </div>
            <div class="mt-4 mr-4">
              <ULink
                to="/points/missions"
                class="flex items-center justify-between py-4 px-4 rounded-md relative hover:bg-neutral-100 hover:dark:bg-neutral-800"
                active-class="[&>#missions]:block [&>div>span]:bg-teal-500 bg-neutral-100 dark:bg-neutral-800"
                inactive-class="[&>#missions]:hidden"
              >
                <div
                  id="missions"
                  class="absolute h-[50px] w-[4px] bg-teal-500 rounded-full left-[-4px]"
                ></div>
                <div class="flex items-center gap-2">
                  <UIcon name="i-heroicons-rectangle-stack-20-solid" />
                  <p class="font-semibold">{{ LL.missions() }}</p>
                </div>
                <UBadge variant="solid">3</UBadge>
              </ULink>
              <ULink
                to="/points/rewards"
                class="flex items-center justify-between py-4 px-4 rounded-md hover:bg-neutral-100 relative mt-1 hover:dark:bg-neutral-800"
                active-class="[&>#rewards]:block [&>div>span]:bg-teal-500 bg-neutral-100 dark:bg-neutral-800"
                inactive-class="[&>#rewards]:hidden"
              >
                <div
                  id="rewards"
                  class="absolute h-[50px] w-[4px] bg-teal-500 rounded-full left-[-4px]"
                ></div>
                <div class="flex items-center gap-2">
                  <UIcon name="i-heroicons-gift-20-solid" />
                  <p class="font-semibold">{{ LL.rewards() }}</p>
                </div>
              </ULink>
            </div>
          </div>
        </div>
        <div class="col-span-12 lg:col-span-9">
          <slot />
        </div>
      </div>
    </UContainer>
    <PageFooter />
    <slot name="app-after" />
    <div id="app-after" />
  </div>
</template>

<script lang="ts" setup>
import { typesafeI18n } from "~/locales/i18n-vue";
import { useUserStore } from "~/store";

const userStore = useUserStore();

const { LL } = typesafeI18n();
</script>
